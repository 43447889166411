import React from 'react';
import {Avatar,Button,CssBaseline,TextField,FormControlLabel,Checkbox,Link,Grid,createMuiTheme,Container,Typography,Box} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles,ThemeProvider } from '@material-ui/core/styles';
import { useForm } from "react-hook-form";
import axios from 'axios';



function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://support.domain.com/">
        support.domain.com
      </Link>{' Since'}
      {new Date().getFullYear()}
    </Typography>
  );
}

const theme = createMuiTheme({
    palette: {
      type: "dark"
    }
  });

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#666',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LoginForm = (props) => {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = data => {
      axios.post('https://support.ht2280.com/api/login',data)
      .then(res=>{
            localStorage.setItem('token',res.data.token);
            window.location.reload(false);
      }).catch(err=>{
            console.log(err);
      });
  }; 
  return (
    <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
            <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
            LOGIN
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="userid"
                label="ID"
                name="userid"
                autoComplete="UserID"
                autoFocus
                inputRef={register}
                error={!!errors.userid}
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="PASS"
                type="password"
                id="password"
                autoComplete="current-password"
                inputRef={register}
                error={!!errors.password}
            />
            <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="REMEMBER ID"
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
            >
                LOGIN
            </Button>
            <Grid container>
                <Grid item>
                <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                </Link>
                </Grid>
            </Grid>
            </form>
        </div>
        <Box mt={8}>
            <Copyright />
        </Box>
        </Container>
    </ThemeProvider>
  );
}
export default LoginForm
