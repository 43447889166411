import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import LoginForm from './views/login';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';

axios.defaults.baseUrl='https://support.ht2280.com/';
axios.defaults.headers.common['Authorization']='Bearer '+ localStorage.getItem('token');
ReactDOM.render(
  <React.StrictMode>
    <LoginForm />
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();
